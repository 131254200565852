<template>
<div class="mainBox">
	<!-- 账号列表 -->
	<div class="con" v-if="isMobile==0">
		<!-- PC及适配 -->
		<div class="container">
			<div class="header">
				<p v-if="accountList.length">{{$t('account.title')}}</p>
				<span v-else>{{$t('account.create')}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<template v-if="accountList.length">
						<div class="account_box">
							<div class="btnBox">
								<div class="g_btn btn" @click="handleCreate">{{$t('account.bind')}}</div>
							</div>
							<div class="listbox">
								<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
									<div>
										<div class="caption">
											<div class="left">{{item.name}}</div>
											<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
										</div>
										<div class="bd">
											<div class="li">
												<div class="title">{{$t('common.exchange')}}</div>
												<div class="text">{{item.web_name}}</div>
											</div>
											<div class="li">
												<div class="title" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('account.walletAddress')}}</div>
												<div class="title" v-else>API Key</div>
												<div class="text">{{item.key}}</div>
											</div>
											
											<div class="li">
												<div class="title">
													{{
														accountFormat2(item.web,item.wallet_type).isT2==1?
														$t('account.'+accountFormat2(item.web,item.wallet_type).label2):
														accountFormat2(item.web,item.wallet_type).label2
													}}
												</div>
												<div class="text">{{item.secret}}</div>
											</div>

											<div class="li">
												<div class="title" v-if="accountFormat3(item.web,item.wallet_type).showLabel3==1">
													{{
														accountFormat3(item.web,item.wallet_type).isT3==1?
														$t('account.'+accountFormat3(item.web,item.wallet_type).label3):
														accountFormat3(item.web,item.wallet_type).label3
													}}
												</div>
												<div class="title" v-else>Account-ID</div>
												<div class="text">{{item.account_id}}</div>
											</div>
											<div class="li">
												<div class="title">{{$t('common.operation')}}</div>
												<div class="text">
													<span class="btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
													<span class="btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</template>
					<el-empty v-else :image="emptyImg" :image-size="120" :description="$t('account.toCreate')">
						<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
					</el-empty>
				</div>
			</div>
		</div>
			
		<!-- 创建账户、修改账户 -->
		<el-dialog :title="isEdit?$t('account.editAcc'):$t('account.create')" :visible.sync="isPop" :show-close="false" center @close="closePop">
			<div class="account_form">
				<div class="item" :class="{g_inp_disable:isEdit}">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select" @click="showExchange">
							<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont el-icon-arrow-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title" v-if="form.wallet_type==1||form.wallet_type==2">{{$t('account.walletAddress')}}</div>
					<div class="title" v-else>API Key</div>
					<div class="text">
						<input v-model="form.key" type="text" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				
				<div class="item">
					<div class="title">{{isT2==1? $t('account.'+label2):label2}}</div>
					<div class="text">
						<input v-model="form.secret" type="password" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<!-- 附加项 -->
				<div class="item" v-if="showLabel3==1">
					<div class="title">{{isT3==1? $t('account.'+label3):label3}}</div>
					<div class="text">
						<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<!-- 备注 -->
				<div class="item">
					<div class="title">{{$t('account.accountName')}}</div>
					<div class="text">
						<input v-model="form.name" type="text" :placeholder="$t('common.optional')" class="inp" />
					</div>
				</div>
				<!-- 邮箱验证码 -->
				<div class="item" v-if="isEdit">
					<div class="title">{{$t('common.email')}}</div>
					<div class="text g_disable_text">{{email}}</div>
				</div>
				<div class="item" v-if="isEdit">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<el-input
								:placeholder="$t('common.inputVerification')"
								type="text"
								v-model="form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
				<div class="tips">
					<p v-if="form.wallet_type==0||!web">{{$t('account.whiteList')}}：&lt;<span class="red">{{ipWhitelist}}</span>&gt;</p>
					<p v-else-if="form.wallet_type==1"><span class="red">{{$t('account.reminder')}}</span>{{$t('account.dexReminder')}}</p>
					<p>{{$t('account.tip')}}</p>
					<p>
						{{$t('account.tip2_1')}}<span class="link" @click="jumpTg">{{$t('account.tip2_2')}}</span>
					</p>
				</div>
				<div class="foot" v-show="!isEdit">
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.confirm')}}</div>
				</div>
				<div class="foot" v-show="isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.save')}}</div>
				</div>
			</div>
		</el-dialog>

		<!-- 删除账户 -->
		<el-dialog :title="$t('account.delAcc')" :visible.sync="showDel" :show-close="false" center @close="closeDelPop">
			<div class="account_form">
				<div class="item">
					<div class="title">{{$t('account.accountName')}}</div>
					<div class="text">
						<input v-model="form.name" type="text" readonly :placeholder="$t('common.optional')" class="inp" />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select">
							<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont el-icon-arrow-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.email')}}</div>
					<div class="text g_disable_text">{{email}}</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<el-input
								:placeholder="$t('common.inputVerification')"
								type="text"
								v-model="form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="g_btn btn" @click="closeDelPop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleDelAcc">{{$t('common.del')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
	<div class="con" v-else-if="isMobile==1">
		<!-- 移动端判定 -->
		<div class="phoneContainer">
			<template v-if="accountList.length">
				<div class="account_box">
					<div class="listbox">
						<div class="item" v-for="(item,index) in accountList" :key="index" :class="{active: activeItem == index}">
							<div>
								<div class="caption">
									<div class="left">{{item.name}}</div>
									<div class="right g_btn" @click="jumpSubManage(item)" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('common.subManage')}}</div>
								</div>
								<div class="bd">
									<div class="li">
										<div class="title">{{$t('common.exchange')}}</div>
										<div class="text">{{item.web_name}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="item.wallet_type==1||item.wallet_type==2">{{$t('account.walletAddress')}}</div>
										<div class="title" v-else>API Key</div>
										<div class="text">{{item.key}}</div>
									</div>
									<div class="li">
										<div class="title">
											{{
												accountFormat2(item.web,item.wallet_type).isT2==1?
												$t('account.'+accountFormat2(item.web,item.wallet_type).label2):
												accountFormat2(item.web,item.wallet_type).label2
											}}
										</div>
										<div class="text">{{item.secret}}</div>
									</div>
									<div class="li">
										<div class="title" v-if="accountFormat3(item.web,item.wallet_type).showLabel3==1">
											{{
												accountFormat3(item.web,item.wallet_type).isT3==1?
												$t('account.'+accountFormat3(item.web,item.wallet_type).label3):
												accountFormat3(item.web,item.wallet_type).label3
											}}
										</div>
										<div class="title" v-else>Account-ID</div>
										<div class="text">{{item.account_id}}</div>
									</div>
									<div class="li">
										<div class="operation">
											<span class="g_btn btn change" @click="handleChange(index)">{{$t('common.modify')}}</span>
											<span class="g_btn btn delete" @click="handleDelete(index)">{{$t('common.del')}}</span>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</template>
			<el-empty class="isPhone" v-else :image="emptyImg_phone" :image-size="120" :description="$t('account.toCreate')">
				<div class="g_btn btn" @click="handleCreate">{{$t('account.newAccount')}}</div>
			</el-empty>
		</div>
		
		<!-- 创建账户、修改账户 -->
		<van-action-sheet 
		v-model="isPop" 
		:title="isEdit?$t('account.editAcc'):$t('account.create')" 
		:closeable="false"
		@closed="closePop">
			<div class="account_form_phone">
				<div class="item" :class="{g_inp_disable:isEdit}">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select" @click="showExchange">
							<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont el-icon-arrow-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title" v-if="form.wallet_type==1||form.wallet_type==2">{{$t('account.walletAddress')}}</div>
					<div class="title" v-else>API Key</div>
					<div class="text">
						<input v-model="form.key" type="text" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<div class="item">
					<div class="title">{{isT2==1? $t('account.'+label2):label2}}</div>
					<div class="text">
						<input v-model="form.secret" type="password" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<!-- 附加项 -->
				<div class="item" v-if="showLabel3==1">
					<div class="title">{{isT3==1? $t('account.'+label3):label3}}</div>
					<div class="text">
						<input v-model="form.account_id" :placeholder="$t('common.input')" class="inp" />
					</div>
				</div>
				<!-- 备注 -->
				<div class="item">
					<div class="title">{{$t('account.accountName')}}</div>
					<div class="text">
						<input v-model="form.name" type="text" :placeholder="$t('common.optional')" class="inp" />
					</div>
				</div>
				<!-- 邮箱验证码 -->
				<div class="item" v-if="isEdit">
					<div class="title">{{$t('common.email')}}</div>
					<div class="text g_disable_text">{{email}}</div>
				</div>
				<div class="item" v-if="isEdit">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<el-input
								:placeholder="$t('common.inputVerification')"
								type="text"
								v-model="form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
				<div class="tips">
					<p v-if="form.wallet_type==0||!web">{{$t('account.whiteList')}}：&lt;<span class="red">{{ipWhitelist}}</span>&gt;</p>
					<p v-else-if="form.wallet_type==1"><span class="red">{{$t('account.reminder')}}</span>{{$t('account.dexReminder')}}</p>
					<p>{{$t('account.tip')}}</p>
					<p>{{$t('account.tip2')}}</p>
				</div>
				<div class="foot" v-show="!isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.confirm')}}</div>
				</div>
				<div class="foot" v-show="isEdit">
					<div class="g_btn btn" @click="closePop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="confirmCreate">{{$t('common.save')}}</div>
				</div>
			</div>
		</van-action-sheet>

		<!-- 删除账户 -->
		<van-action-sheet 
		v-model="showDel" 
		:title="$t('account.delAcc')" 
		:closeable="false"
		@closed="closeDelPop">
			<div class="account_form_phone">
				<div class="item">
					<div class="title">{{$t('account.accountName')}}</div>
					<div class="text">
						<input v-model="form.name" type="text" readonly :placeholder="$t('common.optional')" class="inp" />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select">
							<input v-model="form.exchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont el-icon-arrow-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.email')}}</div>
					<div class="text g_disable_text">{{email}}</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<el-input
								:placeholder="$t('common.inputVerification')"
								type="text"
								v-model="form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
				<div class="foot">
					<div class="g_btn btn" @click="closeDelPop">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleDelAcc">{{$t('common.del')}}</div>
				</div>
			</div>
		</van-action-sheet>
	</div>

	<!-- 交易所选择 -->
	<exchange-dialog
		:show.sync="popExchange"
		:web="web"
		:notEl="true"
		@close="closeExchange"
		@select="handleSelect"
	/>
	<!-- 绑定邮箱 -->
	<emailBind ref="emailBind"></emailBind>
</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	var timer;

	import { Loading } from 'element-ui';
	import ExchangeDialog from '@/components/exchange.vue';
	import emailBind from '@/components/emailBind.vue';
    import { getTradingPairInfo,setTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
	// api
	import {getAccountList,setAccountItem,delAccountItem} from '@/api/account' 
    import { sendEmail } from '@/api/user'
	import {getService} from '@/api/contact'
	// methApi
	import {accountFormat2,accountFormat3} from "@/utils/methApi/accountFormat.js";

	import { mapGetters } from 'vuex'
	import bus from "../bus.js";
	export default ({
		name: 'account',
		components: {
			ExchangeDialog,
			emailBind,
		},
		data() {
			return {
				loading:null,

				isPop: false,
				isEdit: false,
				showDel:false,
				emptyImg: require('@/assets/images/norecord.png'),
				emptyImg_phone: require('@/assets/images/norecord_phone.png'),
				
				activeItem: null,
				
				// 交易所
				popExchange: false,
				web: null,
				
				accountList: [],
				form: {},
				// label类型判定
				isT2: 0,
				label2: "Api Secret",
				isT3: 0,
				label3: "Passphrase",
				showLabel3: 0,
				
				// 邮箱验证
				codeSending: false,
				timeCount: 60,
				firstSend: true,
			}
		},
		computed: {
			...mapGetters(['account','lang','isMobile','email','ipWhitelist']),
		},
		created(){
			// 如果是刚注册进入系统，则打开添加弹窗
			if(this.$route.params.isRegister==1){
				this.handleCreate()
			}
			// 获取账号列表
			this.getAccountList()
			// 移动端右上角按钮
			bus.$on('add',this.handleCreate)
		},
		methods: {
			accountFormat2(web, web_type){
				return accountFormat2(web, web_type);
			},
			accountFormat3(web, web_type){
				return accountFormat3(web, web_type);
			},
			// label类型判定
			labelTypeData(web, web_type) {
				var account2 = accountFormat2(web, web_type);
				this.isT2 = account2.isT2;
				this.label2 = account2.label2;
				var account3 = accountFormat3(web, web_type);
				this.isT3 = account3.isT3;
				this.label3 = account3.label3;
				this.showLabel3 = account3.showLabel3;
			},
			// 交易钱包管理
			jumpSubManage(item){
				// console.log(item)
				this.$router.push({
					path:"/subManage",
					query:{
						web:item.web,
						exchange:item.web_name,
						// yenUnit1:this.yenUnit1,
						// yenUnit2:this.yenUnit2,
					}
				})
			},
			closePop() {
				this.isPop = false;
				this.$nextTick(()=>{
					this.form = {};
					this.activeItem = null;
					this.web=null;
				})
			},
			// 打开新增弹窗
			handleCreate() {
				this.isPop = true;
				this.isEdit = false;
				
				this.isT2= 0;
				this.label2= "Api Secret";
				this.isT3= 0;
				this.label3= "Passphrase";
				this.showLabel3= 0;
				this.form = {};
			},
			// 新增/编辑
			confirmCreate() {
				if(!this.form.web){
					this.$message({
						message: this.$t('tip.selectExchange'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.key){
					var name;
					if(this.form.wallet_type==1||this.form.wallet_type==2){
						name=this.$t('account.walletAddress')
					}else{
						name='API Key'
					}
					this.$message({
						message: this.$t('tip.pleaseEnter',{name})+'！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.secret){
					var name;
					if(this.isT2 == 0){
						name = this.label2
					}else{
						name = this.$t('account.'+this.label2)
					}
					this.$message({
						message: this.$t('tip.pleaseEnter',{name})+'！',
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.form.sms_code&&this.isEdit){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}
				// 添加操作、是已绑定的交易所
				else if(!this.isEdit&&!!this.accountList&&this.accountList.some(item => item.web === this.form.web)){
					this.$message({
						message: this.$t('account.isCreateAddTip'),
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					// 附加项
					if(this.showLabel3 == 1 && !this.form.account_id){
						var name
						if(this.isT3 == 0){
							name = this.label3
						}else{
							name = this.$t('account.' + this.label3)
						}
						this.$message({
							message: this.$t('tip.pleaseEnter',{name})+'！',
							center: true,
							type: 'error',
							offset: 100,
							customClass: 'shotMsg',
						});
					}else{
						this.loading = Loading.service({
							text: 'Loading',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'apploading',
						});
						// 提交表单信息--关闭弹框--获取新列表
						setAccountItem({
							id:this.isEdit?this.accountList[this.activeItem].id:0,
							name:this.form.name,
							web:this.form.web,
							key:this.form.key,
							secret:this.form.secret,
							exchange_account_id:this.form.account_id||0,
							account:this.account,
							email:this.email,
							sms_code:this.form.sms_code,
						}).then(res=>{
							if(res==1){
								// this.getAccountList();
								// 打开盘口选择弹窗
								localStorage.setItem('oldWebId', getTradingPairInfo('web_id'))
								localStorage.setItem('hasBind',1)
								localStorage.setItem('hasEnter',0)
								// 创建或编辑都修改本地存储的交易所
								setTradingPairInfo({
									exchange:this.form.exchange,
									web:this.form.web,
									web_id:this.form.web_id,
									web_type:this.form.wallet_type,
									yenUnit1: getTradingPairInfo('yenUnit1'),
									yenUnit2: getTradingPairInfo('yenUnit2'),
								})
												
								if(!this.isEdit){
									this.$message({
										message: this.$t('tip.addSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
									// 如果是dex进入钱包管理，否则进入手动操盘
									if(this.form.wallet_type==1||this.form.wallet_type==2){
										this.$router.push({
											path:"/subManage",
											query:{
												web:this.form.web,
												exchange:this.form.exchange,
											}
										})
									}else{
										this.$router.push({path:"/stocks"})
									}
								}else{
									this.$message({
										message: this.$t('tip.editSuc'),
										center: true,
										type: 'success',
										offset: 100,
										customClass: 'shotMsg',
									});
									this.$router.push({path:"/stocks"})
								}
								this.isPop = false;
							}else{
								if(!this.isEdit){
									this.$message({
										message: this.$t('tip.addErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}else{
									this.$message({
										message: this.$t('tip.editErr'),
										center: true,
										type: 'error',
										offset: 100,
										customClass: 'shotMsg',
									});
								}
							}
							this.loading.close()
						}).catch(err=>{
							this.loading.close()
						})
					}
				}
			},
			// 交易所
			showExchange() {
				if(this.isEdit)return
				this.popExchange = true;
			},
			closeExchange() {
				this.popExchange = false;
			},
			handleSelect(data) {
				this.labelTypeData(data.web,data.web_type)
				this.form.exchange = data.text;
				this.form.web = data.web;
				this.form.wallet_type = data.web_type;
				this.form.web_id = data.web_id;
				this.web=data.web;
				this.popExchange = false;
			},

			// 打开编辑弹窗
			handleChange(index) {
				if(!this.email){
					// 打开绑定邮箱弹窗
					this.$refs.emailBind.open()
				}else{
					this.activeItem = index;
					this.form = Object.assign({},this.accountList[index])

					this.form.exchange = this.accountList[index].web_name
					this.web=this.accountList[index].web

					this.labelTypeData(this.form.web,this.form.wallet_type)
					// 获取账号信息--显示弹窗
					this.isPop = true;
					this.isEdit = true;
				}
			},
			// 打开删除弹窗
			handleDelete(index) {
				if(!this.email){
					// 打开绑定邮箱弹窗
					this.$refs.emailBind.open()
					return
				}

				this.showDel=true;
				this.activeItem = index;
				this.form =Object.assign({},this.accountList[index])
				this.form.exchange=this.accountList[index].web_name
				this.web=this.accountList[index].web
			},
			closeDelPop(){
				this.showDel=false;
				this.activeItem = null;
			},
			// 删除账号操作
			handleDelAcc(){
				if(!this.form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					this.loading = Loading.service({
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)',
						customClass: 'apploading',
					});
					delAccountItem({
						web:this.accountList[this.activeItem].web,
						account:this.account,
						access_id:this.accountList[this.activeItem].id,
						email:this.email,
						sms_code:this.form.sms_code,
					}).then(res=>{
						// console.log(res)
						if(res==1){
							this.loading.close()

							this.getAccountList();
							this.$message({
								message: this.$t('tip.delSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}else{
							// 删除失败
							this.$message({
								message: this.$t('tip.delErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
						
						this.showDel=false;
						this.activeItem = null;
					}).catch(err=>{
						this.loading.close()
					})
				}
			},
			// 获取账号列表
			getAccountList(){
				this.loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
			
				getAccountList({account:this.account}).then(res => {
					// console.log(res)
					this.accountList=res
					this.loading.close();
				}).catch(err=>{
					this.loading.close();
				});
			},
			
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
					// 发送验证码
					this.timeCount = 60;
					this.codeSending = true;
					let that = this;
					this.firstSend = false;
					clearInterval(timer);
					timer = setInterval(() => {
						if(this.timeCount >= 1) {
							that.timeCount -= 1;
						} else {
							clearInterval(timer);
							this.codeSending = false;
						}
					}, 1000);
				
					sendEmail({
						account:this.account,
						email:this.email,
						type:1,
					})
				}
			},
			// 联系客服
			jumpTg(){
				this.loading = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				getService({
					account:this.account,
					source:1,//0-微信，1-telegram，2-Facebook，3-discord
				}).then(res=>{
					if(!!res){
						if(res.length==1){
							// 如果只有一个联系方式 直接打开
							window.location.href=res[0].wechat
						}else if(res.length>1){
							// 如果有多个联系方式
							try {
								res.forEach(item => {
									if(item.nickname=='系统客服'){
										window.location.href=item.wechat
										throw Error()
									}
								});
							} catch (error) {
								
							}
						}else{
							// 没有联系方式
						}
					}
					this.loading.close()
				}).catch(err=>{
					this.loading.close()
				})
			},
		},
		beforeDestroy() {
			if(timer){
				clearInterval(timer)
				timer=''
			}
			// 在页面销毁前取消事件监听
  			bus.$off('add',this.handleCreate);
			// 子组件销毁
			if(this.$refs.emailBind){
				this.$refs.emailBind.beforeDestroy()
			}
		}
	})
</script>
